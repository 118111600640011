@import '@core/styles/vars';
@import '@core/styles/mixins/dark-mode';

.Discuss {
  display: flex;
  flex-direction: column;
  margin: 0 auto $xl;
  max-width: var(--container);
  width: 100%;

  &-header {
    padding: $md;

    &_top {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    &_title {
      font-size: 1.5em;
    }
  }

  &-back {
    color: var(--color-link-primary);
    height: $button-sm;
    padding: $xs 0;

    &:hover,
    &:focus,
    &:active {
      color: var(--color-link-primary-darken-10);
    }

    &:focus {
      border-radius: var(--border-radius);
      box-shadow: 0 0 0 3px var(--color-link-primary-alpha-25);
    }

    &-icon {
      opacity: 0.5;
      padding-right: $xs;
    }
  }

  &-question {
    &_div {
      padding-bottom: $md;
    }

    &_button {
      background-color: var(--color-link-primary);
      color: var(--color-link-text);

      &:hover {
        background-color: var(--color-link-primary-darken-10);
        color: var(--color-link-text);
      }
    }
  }

  &List {
    --DiscussList-bg: var(--gray100);
    --DiscussList-sidebar-width: 200px;

    @include dark-mode {
      --DiscussList-bg: var(--gray15);
    }

    padding-left: var(--md);
    padding-right: var(--md);

    &-posts.DiscussList-posts {
      @media (max-width: 768px) {
        flex-direction: column;
      }
    }

    &-posts-list {
      flex: 1 1 auto;
      max-width: 100%;
    }

    &-sidebar {
      flex: 0 0 var(--DiscussList-sidebar-width);
      position: sticky;
      top: var(--md);

      @media (max-width: 550px) {
        flex: 1 1 auto;
      }

      /* override box */
      &_box.DiscussList-sidebar_box {
        background: var(--DiscussList-bg);
      }

      &_list {
        list-style-type: none;
        margin: 0;
        padding: 0;
      }

      &_link {
        border: 1px solid transparent;

        &:global(.active) {
          color: var(--blue);

          &:hover,
          &:active,
          &:focus {
            color: var(--blue);
          }
        }
      }

      &_tag {
        color: var(--minimumGray, var(--minimum-gray));
        font-size: 14px;
        height: $button-sm;
        margin: $xs;
        padding: $sm;
      }
    }
  }

  &Post {
    --DiscussPost-background-hover: var(--gray100);
    --DiscussPost-border: #{rgba(black, 0.05)};
    --DiscussPost-border-active: var(--color-input-border-active);
    --DiscussPost-border-focus: var(--color-input-border-focus);
    --DiscussPost-button-width: 70px;

    @include dark-mode {
      --DiscussPost-background-hover: var(--gray15);
      --DiscussPost-border: #{rgba(white, 0.05)};
    }

    border: 1px solid transparent;
    box-sizing: border-box;
    display: flex;
    gap: var(--md);
    padding: var(--md);
    width: 100%;

    @media (max-width: 550px) {
      flex-direction: column;
    }

    + .DiscussPost {
      border-top: 1px solid var(--DiscussPost-border);
    }

    &Actions {
      font-size: 12px;
      margin-top: $md;

      &:empty {
        display: none;
      }

      &_button {
        color: var(--color-text-default);
        height: $button-sm;
        margin: $xs 0;
      }

      &_delete {
        background: var(--red);
        border-radius: 50%;
        color: white;
        cursor: pointer;
        font-weight: bold;
        left: -6px;
        opacity: 0;
        padding: 4px;
        position: absolute;
        top: -17px;
        transform: scale(0.7);
        transition: 0.2s ease-out;
        z-index: 999;

        &:focus {
          opacity: 1;
          transform: scale(1);
        }
      }

      &_wrapper {
        color: var(--minimumGray, var(--minimum-gray));
        height: $button-sm;
        margin: $xs;
        position: relative;

        &:first-child {
          margin-left: 0;
        }

        // this code is duplicated below because we want the delete icon to display
        // when hovering over the entire tag item and when tabbing through the tags
        &:hover {
          .DiscussPostActions_delete {
            opacity: 1;
            transform: scale(1);
          }
        }
      }

      &-action {
        color: var(--color-link-primary);
        height: $button-sm;
        margin: $xs;

        &_comment {
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }

    &-abbreviated {
      &:hover,
      &:active,
      &:focus {
        border-radius: var(--border-radius);

        + .DiscussPost {
          border-top-color: transparent;
        }
      }

      &:hover {
        background-color: var(--DiscussPost-background-hover);
        border: 1px solid var(--DiscussPost-border);
        color: unset;
      }

      &:active,
      &:focus {
        border-color: var(--DiscussPost-border-active);
        box-shadow: 0 0 0 3px var(--DiscussPost-border-focus);
        outline: 0;
      }
    }

    &_icon {
      margin-right: $xs;
    }

    &-metabox_answered {
      background-color: var(--green);
      border-radius: var(--border-radius);
      color: var(--white);
      font-size: 12px;
      padding: $xs;
      text-align: center;
    }

    &-button {
      min-width: var(--DiscussPost-button-width);

      @media (max-width: 550px) {
        flex: 1 1 auto;
      }

      &_icon {
        padding-right: $xs;
      }
    }

    &-content {
      overflow: hidden;
      width: 100%;

      &:first-child {
        margin-left: calc(var(--DiscussPost-button-width) + var(--md));

        @media (max-width: 550px) {
          margin-left: 0;
        }
      }
    }

    &-title {
      color: var(--color-text-default);
      font-size: 18px;
      margin: 0 0 var(--sm);
    }

    &-abbrbody {
      color: var(--color-text-minimum);
      /* stylelint-disable-next-line value-no-vendor-prefix */
      -webkit-line-clamp: 1;
      line-clamp: 1;
      margin: var(--sm) 0;
      overflow: hidden;
      overflow-wrap: anywhere;
      text-overflow: ellipsis;

      @media (max-width: 550px) {
        -webkit-line-clamp: 2;
        line-clamp: 2;
      }
    }

    &-fullbody {
      line-height: 1.4;
    }

    &-details {
      align-items: center;
      display: flex;
      margin-bottom: $md;
    }

    &-date {
      color: var(--color-text-minimum);
      display: inline-flex !important; /* this overrides the DateLine display: flex which pushes everything else to a new line */
      font-size: 12px;
    }

    &-email,
    &-admin {
      background-color: var(--shale);
      border-radius: var(--border-radius);
      color: var(--ivory);
      font-size: 12px;
      margin: 0 0 0 $xs;
      padding: 2px 7px;

      .icon {
        margin-right: $xs;
      }
    }

    &-edited {
      color: var(--shale);
      font-size: 12px;
      font-style: italic;
      margin: 0 0 0 $xs;
    }

    &-delete_text {
      margin-top: $xs;
      text-align: center;
    }

    &-edit {
      margin-top: $md;
      text-align: end;

      &_button:not(:last-child) {
        margin-right: $xs;
      }
    }

    &-solved {
      color: var(--red);
      font-size: 14px;
      padding-right: $sm;

      &_icon {
        background: var(--red);
        border-radius: var(--border-radius);
        color: white;
        font-weight: bold;
        margin-right: $xs;
        padding: 1px;
      }

      &_answered {
        color: var(--green);

        .DiscussPost-solved_icon {
          background: var(--green);
        }
      }
    }

    &-newComment {
      &_inputs {
        display: flex;
        margin-top: $sm;
      }

      &_title {
        margin-bottom: $sm;
        width: 100%;
      }

      &_input {
        width: 50%;

        &:first-child {
          margin-right: $sm;
        }
      }

      &_content {
        width: 100%;
      }
    }
  }

  &New {
    display: grid;
    grid-template-columns: 3fr 1fr;

    @media (max-width: $ipad-mini-portrait) {
      grid-template-columns: auto;
    }

    &-header {
      font-size: 1.5em;
      padding: $md;
    }

    &QuestionForm {
      padding: 0 $md $md;

      &_buttons {
        margin-top: $md;

        // targetting recaptcha
        > div > div > div {
          margin-top: var(--md);
        }
      }

      &_input {
        @include dark-mode {
          background-color: var(--color-bg-page);
        }

        margin-bottom: $sm;
        width: 100%;

        &::placeholder {
          color: var(--gray80);
          font-size: 14px;
        }
      }

      &_content {
        &::placeholder {
          color: var(--gray80);
          font-size: 14px;
        }
      }
    }

    &Suggested {
      padding: $xs $md $md $md;

      &-post {
        border-radius: var(--border-radius);
        color: unset;
        display: block;
        padding: $sm $md;

        &:not(:last-child) {
          border-bottom: 1px solid var(--ivory);
        }

        &:hover {
          @include dark-mode {
            background-color: var(--gray15);
          }

          background-color: var(--gray100);
          border: 1px solid var(--color-border-default);
          border-radius: var(--border-radius);
          color: unset;
        }
      }
    }
  }

  &EditorWrapper {
    @include dark-mode {
      background-color: var(--color-bg-page);
    }

    display: flex;
    flex-direction: column;
    width: 100%;

    &-large {
      min-height: 200px;
    }
  }

  .markdown-body {
    style {
      display: none;
    }
  }
}
